<template>
  <div class="finances">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo saldo</span>
    </button>
    <b-table
      :data="allBankBalances"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ $moment(props.row.date).format('DD/MM/YYYY') }}
      </b-table-column>

      <b-table-column
        field="bank"
        label="Banco"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{
          props.row.bank && props.row.bank.name ? props.row.bank.name : 'N/A'
        }}
      </b-table-column>

      <b-table-column
        field="balance"
        label="Saldo"
        :searchable="true"
        sortable
        v-slot="props"
      >
        R$ {{ parseFloat(props.row.balance).toFixed(2) }}
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Banco *">
              <b-select
                expanded
                placeholder="Selecione o banco"
                required
                v-model="model.bank_id"
              >
                <option
                  v-for="(item, index) in allBanks"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name.toUpperCase() }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Data *">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="model.date"
                inline
              >
              </b-datepicker>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.balance"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/bank-balances/store/service'
import BankBalance from '../models/bank-balance'

export default {
  name: 'BankBalances',
  data() {
    return {
      model: BankBalance.model,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('bankBalances', ['allBankBalances']),
    ...mapState('banks', ['allBanks']),
  },
  methods: {
    ...mapActions('bankBalances', ['getAllBankBalances']),
    ...mapActions('banks', ['getAllBanks']),
    async init() {
      await this.getAllBankBalances()
      await this.getAllBanks()
    },
    onEdit(item) {
      this.model = { ...item }
      this.model.balance = parseFloat(this.model.balance).toFixed(2)

      this.model.date = new Date(this.$moment(this.model.date))

      delete this.model.bank
    },
    async save() {
      const bankBalance = {
        ...this.model,
      }

      bankBalance.date = this.$moment(bankBalance.date).format('YYYY-MM-DD')

      bankBalance.balance = bankBalance.balance.replace('R$ ', '')
      bankBalance.balance = bankBalance.balance.replace(',', '')

      try {
        const save = bankBalance.id
          ? await service.updateBankBalance(bankBalance)
          : await service.saveBankBalance(bankBalance)

        await this.$success('Saldo')
        location.reload(true)
      } catch (error) {
        this.$error(error.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask(
          'Você deseja remover o saldo registrado?'
        )

        if (result.isConfirmed) {
          await service.deleteBankBalance(id)

          await this.$delete('Saldo')
          location.reload(true)
        }
      } catch (error) {
        console.log(error)
        this.$error(error)
      }
    },
  },
}
</script>

<style scoped></style>